import {
  DistributionType,
  DistributionTypeEnum,
  dropErrorMessage,
  giftCodeErrorMessage,
  isGiftCodeDrop,
  isPurchaseDrop,
  pickGraphqlCode,
  useGetNftQuery,
  useItemTransferByDropMutation,
  useItemTransferByGiftCodeMutation,
  useSessionCreateByDropMutation,
} from '@collection-platform-frontend/api';
import {
  delay,
  formatPrice,
  useInterval,
} from '@collection-platform-frontend/shared';
import {
  Accordion,
  Button,
  MaskInput,
  Typography,
} from '@collection-platform-frontend/ui';
import { motion } from 'framer-motion';
import { FC, useCallback, useEffect, useState } from 'react';
import { useReward } from 'react-rewards';

import { PropertyItem } from '../collection';
import { DropConfirm } from './confirm';

export type DropOperation = {
  name?: string | null;
  image?: string | null;
};

export type DropActionProps = {
  minted?: boolean;
  debug?: boolean;
  applicationId: string;
  dropId: string;
  initCode?: string[];
  primaryStyle?: string;
  distributionType: DistributionType;
  price?: number | null;
  description?: string | null;
  notice?: string | null;
  acquisitionLimitCount?: number | null;
  inventoryQuantity?: number | null;
  totalQuantity?: number | null;
  acquireAuto?: boolean;
  onSubmit?: (operation?: DropOperation[]) => void;
  onConfirm?: () => void;
  onReset?: () => void;
};

export const DropAction: FC<DropActionProps> = ({
  debug,
  minted,
  applicationId,
  dropId,
  initCode,
  primaryStyle,
  distributionType,
  price,
  inventoryQuantity,
  acquisitionLimitCount,
  totalQuantity,
  description,
  notice,
  acquireAuto,
  onSubmit,
  onReset,
  onConfirm,
}) => {
  const [code, setCode] = useState<string>();
  const [autoTransfer, setAutoTransfer] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showReward, setShowReward] = useState<boolean>(minted ?? false);
  const [droppedNftIds, setDroppedNftIds] = useState<string>();
  const [acquiredNfts, setAqueiredNfts] = useState<DropOperation[]>([]);

  const priceValue = price
    ? formatPrice({
        amount: price,
        currencyCode: 'JPY',
        locale: 'ja-JP',
      })
    : undefined;

  const [{ fetching: itemTransferByGiftCodeFetching }, itemTransferByGiftcode] =
    useItemTransferByGiftCodeMutation();

  const [{ fetching: itemTransferByDropFetching }, itemTransferByDrop] =
    useItemTransferByDropMutation();

  const [{ fetching: sessionCreateByDropFetching }, sessionCreateByDrop] =
    useSessionCreateByDropMutation();

  const fetching =
    itemTransferByGiftCodeFetching ||
    itemTransferByDropFetching ||
    sessionCreateByDropFetching;

  const [{ data: nftDetail }] = useGetNftQuery({
    pause: !droppedNftIds,
    variables: {
      id: droppedNftIds?.[0] ?? '',
    },
  });

  const dropedItem = nftDetail?.nft.item;

  const transfer = useCallback(
    async (code?: string): Promise<any | undefined> => {
      if (!dropId) {
        return Promise.resolve({
          data: null,
          error: null,
        });
      }

      switch (distributionType) {
        case DistributionTypeEnum.BY_PURCHASE: {
          const cancelUrl = new URL(
            window.location.pathname,
            window.location.origin,
          );
          const successUrl = new URL('/checkout', window.location.origin);

          return sessionCreateByDrop({
            dropId,
            cancelUrl: cancelUrl.toString(),
            successUrl: successUrl.toString(),
          });
        }
        case DistributionTypeEnum.BY_DROP:
          return itemTransferByDrop({
            applicationId,
            dropId,
          });
        case DistributionTypeEnum.BY_GIFT_CODE: {
          if (code) {
            return itemTransferByGiftcode({
              code,
              applicationId,
              dropId,
            });
          }
        }
      }
    },
    [
      sessionCreateByDrop,
      itemTransferByGiftcode,
      itemTransferByDrop,
      applicationId,
      dropId,
      distributionType,
    ],
  );

  const transferComplete = useCallback(
    (operation?: DropOperation[]) => {
      window.scrollTo(0, 0);
      onSubmit && onSubmit(operation);
      setShowReward(true);
    },
    [onSubmit],
  );

  const transferWithProcess = useCallback(() => {
    if (debug) {
      delay(2000).then(() => {
        transferComplete([
          {
            name: 'test',
            image:
              'https://storage.googleapis.com/collection-platform-api-develop-assets/uploads/808dd35c-207e-cb9d-4913-38b122d5ad11.jpeg',
          },
        ]);
      });
      return Promise.resolve();
    }

    const transferCode = async (code?: string) =>
      transfer(code).then((result) => {
        const { error, data } = result;

        const errorCode = pickGraphqlCode(error);
        if (errorCode) {
          const errorCodeMessage =
            giftCodeErrorMessage[errorCode] ??
            dropErrorMessage[errorCode] ??
            errorCode;

          setErrorMessage(errorCodeMessage);
          throw new Error(errorCodeMessage);
        } else {
          setErrorMessage(undefined);
        }

        if (data) {
          if (isPurchaseDrop(distributionType)) {
            window.location.href = data.sessionCreateByDrop.url;
            return;
          }

          const result =
            data?.itemTransferByGiftCode ?? data?.itemTransferByDrop;
          const nftIds = result?.option?.nftIds;
          if (nftIds) {
            setDroppedNftIds(nftIds);
          }
        }
      });

    const transferAll = async () => {
      if (initCode) {
        for (const initCodeItem of initCode) {
          await transferCode(initCodeItem);
        }
      } else if (isGiftCodeNeeded) {
        await transferCode(code);
      } else {
        await transferCode();
      }
    };

    return transferAll();
  }, [transfer, distributionType, transferComplete, debug, initCode, code]);

  const { reward: rewardLeft, isAnimating: isAnimatingLeft } = useReward(
    'rewardLeft',
    'confetti',
    {
      angle: 70,
      lifetime: 240,
      spread: 90,
      elementCount: 100,
      position: 'fixed',
    },
  );
  const { reward: rewardRight, isAnimating: isAnimatingRight } = useReward(
    'rewardRight',
    'confetti',
    {
      angle: 100,
      lifetime: 240,
      spread: 90,
      elementCount: 100,
      position: 'fixed',
    },
  );

  useInterval(() => {
    if (showReward && (!isAnimatingRight || !isAnimatingLeft)) {
      rewardLeft();
      rewardRight();
    }
  });

  useEffect(() => {
    if (!minted) {
      return;
    }

    setShowReward(true);
  }, [minted]);

  useEffect(() => {
    if (!acquireAuto || !initCode || fetching || errorMessage || autoTransfer) {
      return;
    }

    setAutoTransfer(true);
  }, [
    initCode,
    fetching,
    transferWithProcess,
    acquireAuto,
    errorMessage,
    autoTransfer,
  ]);

  useEffect(() => {
    if (!autoTransfer) {
      return;
    }

    transferWithProcess();
    // MEMO：評価するのは一度きりにしたいので、dependenciesはautoTransferのみに
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoTransfer]);

  useEffect(() => {
    if (!dropedItem) {
      return;
    }
    const { title: name, thumbnailAssetUrl: image } = dropedItem;
    setAqueiredNfts((prev) => [...prev, { name, image }]);
  }, [dropedItem]);

  useEffect(() => {
    if (!dropedItem || showReward) {
      return;
    }

    if (initCode) {
      if (initCode.length === acquiredNfts.length) {
        transferComplete(acquiredNfts);
      }
    } else {
      transferComplete([
        {
          name: dropedItem.title,
          image: dropedItem.thumbnailAssetUrl,
        },
      ]);
    }
  }, [dropedItem, showReward, transferComplete, initCode, acquiredNfts]);

  const onChange = useCallback(
    (value: string) => {
      setErrorMessage(undefined);

      const newValue = value.split('-').join('');
      if (newValue?.length === 16) {
        setCode(newValue.toUpperCase());
      } else {
        setCode(undefined);
      }
    },
    [setCode, setErrorMessage],
  );

  const onTransfer = useCallback(async () => {
    transferWithProcess();
  }, [transferWithProcess, initCode]);

  const isGiftCodeNeeded = isGiftCodeDrop(distributionType) && !acquireAuto;
  const isPurchase = priceValue && isPurchaseDrop(distributionType);
  const hasPurchaseLimit = 0 < (acquisitionLimitCount ?? 0);
  const hasStockLimit = inventoryQuantity !== null;
  const showResult = isGiftCodeNeeded ? minted : minted || !!errorMessage;

  return (
    <div className="relative">
      <motion.div
        className="flex flex-col items-center justify-center"
        animate={
          showResult
            ? {
                opacity: 0,
                display: 'none',
              }
            : {
                opacity: 1,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        {isGiftCodeNeeded ? (
          <div className="flex flex-col w-full mb-6">
            <Typography variant="h3" className="pb-2">
              シリアルコードを入力
            </Typography>

            <div className="min-w-[250px] text-left">
              <MaskInput
                disabled={fetching}
                minLength={16}
                maxLength={19}
                placeholder={'____-____-____-____'}
                required
                // type="email"
                // inputMode="email"
                defaultValue={initCode}
                pattern="^[0-9a-zA-Z]+$"
                forceUppercase={true}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                errorMessage={errorMessage}
                onChangeValue={onChange}
              />
            </div>
            {!errorMessage && (
              <Typography variant="caption" className="pt-1">
                ハイフン(-)抜きの半角英数字を入力してください
              </Typography>
            )}
          </div>
        ) : (
          errorMessage && (
            <Typography
              variant="caption"
              className="py-1 text-wallet-light-error dark:text-wallet-error"
            >
              {errorMessage}
            </Typography>
          )
        )}
        <div className="flex flex-col justify-center w-full">
          {isPurchase && (
            <div className="flex flex-col w-full mb-6">
              <Typography variant="h2">{priceValue}</Typography>
              {hasPurchaseLimit && (
                <Typography
                  variant="caption"
                  className="pt-1 text-wallet-secondary"
                >
                  {`お一人様${acquisitionLimitCount}個まで`}
                </Typography>
              )}
            </div>
          )}
          <Button
            primary
            className={primaryStyle}
            loading={fetching}
            disabled={(isGiftCodeNeeded && !code) || minted}
            onClick={onTransfer}
          >
            {isPurchase ? '購入手続きへ' : '取得する'}
          </Button>
        </div>
      </motion.div>
      <DropConfirm
        showResult={showResult}
        errorMessage={errorMessage}
        isCodeNeeded={isGiftCodeNeeded}
        onConfirm={onConfirm}
        onReset={onReset}
      />
      <div className="relative w-full">
        <span id="rewardLeft" className="absolute bottom-0 left-0" />
        <span id="rewardRight" className="absolute bottom-0 right-0" />
      </div>
      {!minted && isPurchase && (
        <div className="mt-6 space-y-6">
          {hasStockLimit && (
            <div className="grid grid-cols-2 gap-4">
              <PropertyItem
                label="在庫状況"
                value={inventoryQuantity?.toString() ?? '-'}
              />
              <PropertyItem
                label="総発行数"
                value={totalQuantity?.toString() ?? '-'}
              />
            </div>
          )}
          {description && (
            <Accordion title="商品説明" initOpen={true}>
              <Typography
                markdown
                variant="caption"
                className="py-6 text-wallet-secondary"
              >
                {description}
              </Typography>
            </Accordion>
          )}
          {notice && (
            <Accordion title="注意事項" initOpen={true}>
              <Typography
                markdown
                variant="caption"
                className="py-6 text-wallet-secondary"
              >
                {notice}
              </Typography>
            </Accordion>
          )}
        </div>
      )}
    </div>
  );
};
