import {
  DistributionType,
  DistributionTypeEnum,
} from '@collection-platform-frontend/api';
import { cn, format, isAfter } from '@collection-platform-frontend/shared';
import { Button, Image, Typography } from '@collection-platform-frontend/ui';
import { StaticImageData } from 'next/image';
import Link from 'next/link';
import { FC } from 'react';

type ImageData = string | StaticImageData;

type DropItemProps = {
  image: ImageData;
  title: string;
  price?: number;
  startAt?: Date;
};

type DropBannerItemProps = DropItemProps & {
  linkPath?: string;
};

export const DropBannerItem: FC<DropBannerItemProps> = ({
  image,
  title,
  price,
  startAt,
  linkPath,
}) => {
  const isOpen = !startAt || isAfter(startAt);
  const content = (
    <div className="relative overflow-hidden aspect-square rounded-3xl min-w-[200px]">
      <Image
        src={image}
        width={1200}
        height={1200}
        alt="spotlight_drop_item"
        className="aspect-square"
      />
      {!isOpen && (
        <div className="absolute top-0 px-3 py-2 mt-4 ml-4 bg-white rounded-full w-fit">
          <Typography variant="h4">
            {`${format(startAt, 'YYYY年MM月DD日')} 〜 `}
          </Typography>
        </div>
      )}

      <div className="absolute bottom-0 w-full px-5 pt-6 pb-4 bg-gradient-to-t from-black">
        <Typography variant="h3" className="text-white">
          {title}
        </Typography>
        {price && price > 0 ? (
          <Typography variant="body2" className="text-white">
            {price}円
          </Typography>
        ) : (
          <Typography variant="body2" className="text-white">
            無料配布
          </Typography>
        )}
      </div>
    </div>
  );

  return isOpen && linkPath ? <Link href={linkPath}>{content}</Link> : content;
};

type DropLinkBannerItemProps = DropItemProps & {
  desctiption?: string;
  externalLink?: string;
  onLink?: () => void;
  onExternalLink?: (url: string) => void;
  distributionType?: DistributionType;
};

export const DropLinkBannerItem: FC<DropLinkBannerItemProps> = ({
  image,
  title,
  desctiption,
  startAt,
  externalLink,
  onLink,
  onExternalLink,
  distributionType,
}) => {
  const isOpen = !startAt || isAfter(startAt);
  const isDrop =
    distributionType && distributionType === DistributionTypeEnum.BY_DROP;
  const externalLinkButtonLabel = isDrop ? '詳細はこちら' : 'ご購入はこちら';
  return (
    <div className="flex flex-col w-full gap-4 p-6 shadow-3xl rounded-3xl">
      {image && (
        <Image
          alt={`${title}-thumbnail`}
          imageClassName="rounded-lg aspect-2/1"
          src={image}
          width={1200}
          height={600}
        />
      )}
      <div>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="h3">{desctiption}</Typography>
      </div>
      <div className="flex flex-col flex-wrap items-center w-full gap-2 sm:items-end sm:justify-between sm:flex-nowrap sm:flex-row">
        {!isDrop ? (
          isOpen ? (
            <Button
              primary
              className={cn('bg-cloudear-blue w-full sm:w-fit h-14 sm:h-16')}
              onClick={onLink}
            >
              受け取りページはこちら
            </Button>
          ) : (
            <Typography
              variant="caption"
              className="pb-1 text-wallet-secondary"
            >
              受け取りは{format(startAt, 'YYYY年MM月DD日')}から
            </Typography>
          )
        ) : (
          <div></div>
        )}
        {externalLink && (
          <Button
            className="w-full h-14 sm:w-fit sm:h-16"
            onClick={() => onExternalLink?.(externalLink)}
          >
            {externalLinkButtonLabel}
          </Button>
        )}
      </div>
    </div>
  );
};
