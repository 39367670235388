import {
  useCurrentCustomer,
  useGetCustomerQuery,
  useGetMyNftDetailQuery,
  useGetNftDetailQuery,
} from '@collection-platform-frontend/api';
import {
  ActionMenuButton,
  BackButton,
  Button,
  Container,
  IPageLayout,
  LineLogo,
  TwitterLogo,
  useToast,
} from '@collection-platform-frontend/ui';
import { PaperClipIcon } from '@heroicons/react/24/outline';
import { NextPage } from 'next';
import { Router, useRouter } from 'next/router';
import { NextSeo, NextSeoProps } from 'next-seo';
import { useCallback, useEffect } from 'react';

import { ItemDetail } from '../../components/collection';

const actionMenus = [
  {
    label: 'リンクをコピー',
    icon: <PaperClipIcon className="w-5 h-5" />,
    value: 'clip',
  },
  {
    label: 'Twitterで共有',
    icon: <TwitterLogo className="w-5 h-5" />,
    value: 'twitter',
  },
  {
    label: 'Lineで共有',
    icon: <LineLogo className="w-5 h-5" />,
    value: 'line',
  },
];

// TODO: 暫定対応：シェア可能なコントラクト一覧を設定、本来はAniqueのapplicationテーブルに紐いているもののみ可能にする
const publishableContractNames = ['guiguikuru', 'SerialExperimentsLain25th'];

type RenderProps = {
  PageLayout: IPageLayout;
  currentApplicationId?: string;
  signupPagePath?: string;
  useNftFilter?: boolean;
  isPublic?: boolean;
  addLayoutProps?: any;
};

type PageRouter = Router & {
  query: {
    id: string;
  };
};

type PageProps = {
  seoData?: NextSeoProps;
};

export const CollectionDetailPageRender = ({
  currentApplicationId,
  signupPagePath,
  useNftFilter,
  isPublic,
  PageLayout,
  addLayoutProps,
}: RenderProps) => {
  const Page: NextPage<PageProps> = ({ seoData }) => {
    const { isReady, query, push, asPath, replace } = useRouter() as PageRouter;
    const { success } = useToast();

    const {
      customer,
      fetching: customerFething,
      authUser,
      registered,
    } = useCurrentCustomer(currentApplicationId, !signupPagePath);
    const myCustomer = customer?.myCustomer;

    const variables = {
      id: query.id,
      applicationId: useNftFilter ? currentApplicationId : undefined,
    };
    const [{ data: publicNft, fetching: publicNftFetching }] =
      useGetNftDetailQuery({
        variables,
        pause: !isReady || !isPublic || registered,
        requestPolicy: 'network-only',
      });

    const [{ data: privateNft, fetching: privateNftFetching }] =
      useGetMyNftDetailQuery({
        variables,
        pause: !isReady || !registered,
        requestPolicy: 'network-only',
      });

    const nftFetching = publicNftFetching || privateNftFetching;
    const privateNftItem = privateNft?.myNfts.edges?.map(({ node }) => node)[0];
    const publicNftItem = publicNft?.nfts.edges?.map(({ node }) => node)[0];
    const currentNft = privateNftItem || publicNftItem;

    const [{ data: customerResult }] = useGetCustomerQuery({
      variables: {
        customerId: currentNft?.customerId ?? '',
      },
      pause: !isReady || !currentNft?.customerId,
      requestPolicy: 'network-only',
    });
    const owner = customerResult?.customer;

    const { item, contractDisplayName, contractName, serialNumber } =
      currentNft ?? {
        item: undefined,
        contractDisplayName: undefined,
        serialNumber: NaN,
      };
    const externalDomainViewUrl = item?.externalDomainViewUrl;
    const isPublishableContract = contractName
      ? publishableContractNames.includes(contractName)
      : false;
    const isOwnItem = !!myCustomer?.email && !!privateNftItem;

    useEffect(() => {
      if (customerFething || nftFetching || !!currentNft) {
        return;
      }

      replace('/collection');
    }, [customerFething, nftFetching, currentNft, replace]);

    useEffect(() => {
      if (customerFething || registered || !signupPagePath) {
        return;
      }

      push({
        pathname: signupPagePath,
        query: {
          callbackUrl: asPath,
        },
      });
    }, [customerFething, registered, push, asPath]);

    const onAction = useCallback(
      (actionCode: string) => {
        const currentUrl = window.location.href;
        if (actionCode === 'clip') {
          navigator.clipboard.writeText(currentUrl).then(() => {
            success('リンクをコピーしました。');
          });
          return;
        }

        const text = [
          currentNft?.contractDisplayName,
          `${currentNft?.item?.title} ${
            currentNft?.serialNumber ? `#${currentNft?.serialNumber}` : '#-'
          }`,
          currentUrl,
        ].join('\n');
        switch (actionCode) {
          case 'twitter': {
            const url = new URL('https://twitter.com/intent/tweet');
            url.searchParams.set('text', text);

            window.open(url.toString(), '_blank');
            break;
          }
          case 'line': {
            const url = new URL(
              `https://line.me/R/msg/text/?${encodeURIComponent(text)}`,
            );

            window.open(url.toString(), '_blank');
            break;
          }
        }
      },
      [success, currentNft],
    );

    const onBack = useCallback(() => {
      push('/collection');
    }, [push]);

    const onMarket = useCallback(() => {
      if (!externalDomainViewUrl) {
        return;
      }

      window.open(externalDomainViewUrl);
    }, [externalDomainViewUrl]);

    const children = (
      <Container>
        <div className="flex flex-col p-4">
          <div className="flex items-center justify-between w-full text-wallet-light-primary dark:text-wallet-primary z-element">
            {isOwnItem ? <BackButton onClick={onBack} /> : <div />}
            {isPublic && isPublishableContract && (
              <ActionMenuButton menus={actionMenus} onAction={onAction} />
            )}
          </div>
          <ItemDetail
            skeleton={nftFetching}
            contractName={contractDisplayName}
            name={item?.title}
            description={item?.description}
            image={item?.thumbnailAssetUrl}
            serialNumber={serialNumber}
            editionSize={item?.totalQuantity}
            properties={item?.properties}
            copyrights={item?.copyrights}
            isOwnItem={isOwnItem}
            publicContents={item?.publicContents}
            unlockableContents={item?.unlockableContents}
            user={isPublic ? owner : undefined}
            onView={() => push(`/collection/${currentNft?.id}/viewer`)}
            onView3D={() => push(`/collection/${currentNft?.id}/3d-viewer`)}
            onViewEpub={() => push(`/collection/${currentNft?.id}/epub-viewer`)}
          />
          {externalDomainViewUrl && (
            <Button primary onClick={onMarket}>
              {contractDisplayName} の詳細へ
            </Button>
          )}
        </div>
      </Container>
    );

    return (
      <PageLayout authUser={authUser} user={myCustomer} {...addLayoutProps}>
        {seoData && <NextSeo {...seoData} />}
        {children}
      </PageLayout>
    );
  };

  return Page;
};
